import React, { useEffect, useState, useRef } from "react";
import Header from "../../Wrapper/Header";
import CarouselComponent2 from "./CarouselComponent2";
import VideoComponent from "./VideoComponent";
import products_en from "./product_en.json";
import products_tr from "./product_tr.json";
import { useParams } from "react-router-dom";
import Loader from "../Home/Loader";
import FooterComponent from "../Home/FooterComponent";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
const ProductComponent = (props) => {
  const form = useRef();
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_r0ed87o",
        "template_07eyz4l",
        form.current,
        "22QMggfsoYJvp2Lah"
      )
      .then(
        (result) => {
          toast.success("Mesajınız gönderildi");
        },
        (error) => {
          toast.error("Mesajınız gönderilmedi!");
        }
      );
  }
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);
  const { name } = useParams();

  const wantedProduct_en = products_en.find(
    (product_en) => product_en.name == name
  );
  const wantedProduct_tr = products_tr.find(
    (product_tr) => product_tr.name == name
  );

  const currentProduct =
    props.currentLanguageCode == "tr" ? wantedProduct_tr : wantedProduct_en;

  return (
    <>
      <Helmet>
        <title>{`SOHO Fertilizer | ${wantedProduct_tr.name}`}</title>
        <meta
          property="title"
          content={`SOHO Fertilizer | ${props.strings["urunler"]}`}
        />

        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content={`SOHO Fertilizer | ${props.strings["urunler"]}`}
        />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content={"https://www.sohofertilizer.com/products"}
        />

        <link
          rel="canonical"
          href={"https://www.sohofertilizer.com/products"}
        />
      </Helmet>
      <ToastContainer />
      <Loader loading={loading}></Loader>
      <div className="row justify-content-center py-0 h-10vh">
        <div className="zi justify-content-center position-fixed p-0">
          <div className="logoSoho" style={{ backgroundColor: "white" }}>
            <a href="/">
              <img
                className="logoSoho2"
                src="/assets/images/logoSoho.svg"
                alt="soho fertilizer damlama gübreleri demir organik"
              />
            </a>
          </div>
        </div>
      </div>
      <Header />
      <section id="team" className="our-team padding-section-product">
        <div className="grid-row">
          <div className="grid-col-row clear">
            <div className="col-xl-12 text-xl-left text-center mb-xl-0  ">
              <div className="title">{wantedProduct_en.name}</div>

              <div className="container-fluid p-0 padding-product">
                <div className="row">
                  <div className="align-items-center p-0 product-row ">
                    <div className="container-fluid col-lg-6 col-md-12">
                      <div className="row" style={{ paddingBottom: "10px" }}>
                        <div className="grid-contact p-0">
                          <div className="row">
                            {props.currentLanguageCode === "en" ? (
                              wantedProduct_tr.glb.endsWith(".glb") ? (
                             <> <model-viewer
                                  className="img-fluid"
                                  style={{ height: "50vh" }}
                                  src={wantedProduct_tr.glb}
                                  ar
                                  shadow-intensity="1"
                                  camera-controls
                                  touch-action="pan-y"
                                ></model-viewer>
                                <p className="white"> {props.strings["tap"]}</p></>  
                              ) : wantedProduct_tr.glb.endsWith(".png") ? (
                                <img
                                  className="img-fluid"
                                  src={wantedProduct_tr.glb}
                                  alt={wantedProduct_en.name}
                                 
                                />
                              ) : null
                            ) : wantedProduct_en.glb.endsWith(".glb") ? (
                            <><model-viewer
                                className="img-fluid"
                                style={{ height: "50vh" }}
                                src={wantedProduct_en.glb}
                                ar
                                shadow-intensity="1"
                                camera-controls
                                touch-action="pan-y"
                              ></model-viewer>
                              <p className="white"> {props.strings["tap"]}</p></>  
                            ) : wantedProduct_en.glb.endsWith(".png") ? (
                              <img
                                className="img-fluid"
                                src={wantedProduct_en.glb}
                                alt={wantedProduct_en.name}
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="d-flex justify-content-center">
                  <div className="container-fluid col-lg-5 col-md-12">
                    <div className="window-tabs big-window-tab">
                      <table className="table">
                        <tbody className="td_product text-start">
                          {Object.keys(currentProduct)
                            .slice(10)
                            .map((key) => (
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "400",
                                  }}
                                >
                                  {" "}
                                  {key}
                                </td>
                                <td className="text-end">
                                  {key === "PH"
                                    ? currentProduct[key]
                                    : `%${currentProduct[key]}`}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="container-fluid d-flex justify-content-center p-3">
                      <div className="row d-flex justify-content-center">
                        <div className="col-5 ">
                          {Object.keys(currentProduct)
                            .slice(0, 1)
                            .map((key) => (
                              <a
                                className="sertifika"
                                href={wantedProduct_en.analiz_pdf}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src={
                                    props.currentLanguageCode === "en"
                                      ? "/assets/images/anaysis_report.svg"
                                      : "/assets/images/analiz.svg"
                                  }
                                  className="iconDetails aw-zoom"
                                  alt="soho fertilizer damlama gübreleri demir organik"
                                />
                              </a>
                            ))}
                        </div>
                        <div className="col-5">
                          {Object.keys(currentProduct)
                            .slice(1, 2)
                            .map((key) => (
                              <a
                                className="sertifika"
                                href={wantedProduct_en.tescil_pdf}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src={
                                    props.currentLanguageCode === "en"
                                      ? "/assets/images/registration.svg"
                                      : "/assets/images/tescil.svg"
                                  }
                                  className="iconDetails aw-zoom "
                                  alt="soho fertilizer damlama gübreleri demir organik"
                                />
                              </a>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-5">
            <div className="video-lg videoSoho-p aw-zoom">
              <video
                controls
                loop
                className="videoSoho "
                src={wantedProduct_en.video}
                poster={wantedProduct_en.poster}
              ></video>
            </div>
            <div className="video-md ">
              <video
                controls
                loop
                className="videoSoho"
                src={wantedProduct_en.video}
                poster={wantedProduct_en.poster}
              ></video>
            </div>
          </div>
          <div className="container-fluid ">
            <div className="row">
              <div className="contact-row d-flex justify-content-center ">
                <div className="col-lg-5 col-md-8 col-sm-8 d-flex justify-content-center dashContact dashShadow p-3">
                  <form
                    ref={form}
                    onSubmit={sendEmail}
                    className="message-form clear"
                  >
                    <div className="row">
                      <p className="col-md-12 col-sm-8 message-form-productName">
                        <div className="contact-head d-flex justify-content-center">
                          <div className="title-contact">
                            <span className="main-title">
                              {props.strings["iletisim_1"]}
                              &nbsp;
                              <span className="blue">
                                {props.strings["iletisim_2"]}
                              </span>
                            </span>
                          </div>
                        </div>
                        <label className="label_text" htmlFor="author">
                          {props.strings["urun_adi"]}
                          <span className="required"></span>
                        </label>
                        <select>
                          <option className="product_options" selected>
                            {wantedProduct_en.name}
                          </option>
                        </select>
                      </p>
                    </div>
                    <div className="row">
                      <p className="col-md-6 message-form-author">
                        <label className="label_text" htmlFor="author">
                          {props.strings["ad-soyad"]}{" "}
                          <span className="required"></span>
                        </label>
                        <input
                          id="author"
                          name="name"
                          type="text"
                          required
                          placeholder={props.strings["ad-soyad"]}
                          size={30}
                          aria-required="true"
                        />
                      </p>
                      <p className="col-md-6 message-form-email">
                        <label className="label_text" htmlFor="author">
                          {props.strings["e-mail"]}{" "}
                          <span className="required"></span>
                        </label>
                        <input
                          id="email"
                          name="email"
                          type="text"
                          required
                          placeholder="E-mail"
                          size={30}
                          aria-required="true"
                        />
                      </p>
                    </div>
                    <div className="row">
                      <p className="col-md-6 message-form-phone">
                        <label className="label_text" htmlFor="author">
                          {props.strings["telefon"]}{" "}
                          <span className="required"></span>
                        </label>
                        <input
                          id="phone"
                          name="phone"
                          required
                          type="tel"
                          placeholder={props.strings["telefon"]}
                          size={20}
                          aria-required="true"
                        />
                      </p>
                      <p className="col-md-6 message-form-company">
                        <label className="label_text" htmlFor="author">
                          {props.strings["sirket"]}{" "}
                          <span className="required"></span>
                        </label>
                        <input
                          id="company"
                          name="company"
                          required
                          type="text"
                          placeholder={props.strings["sirket"]}
                          size={30}
                          aria-required="true"
                        />
                      </p>
                    </div>
                    <div className="row">
                      <label htmlFor="author" className="label_text">
                        {props.strings["mesaj"]}{" "}
                        <span className="required"></span>
                      </label>
                      <p className="col-md-12 col-sm-8 message-form-message message_product">
                        <textarea
                          id="message"
                          name="message"
                          cols={20}
                          rows={10}
                          placeholder={props.strings["mesaj"]}
                          aria-required="true"
                          defaultValue={""}
                        />
                      </p>
                    </div>
                    <div className="d-flex justify-content-center">
                      {" "}
                      <Button
                        className="form-submit rectangle-button green medium"
                        name="submit"
                        type="submit"
                        id="submit"
                        defaultValue="Send"
                      >
                        {props.strings["gonder"]}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <p> </p>
          <h5 className="otherProducts">{props.strings["diger_urunler"]}</h5>
          <CarouselComponent2 />
        </div>
      </section>
      <FooterComponent />
      <a href="#" id="scroll-top" className="scroll-top">
        <i className="fa fa-angle-up" />
      </a>
    </>
  );
};

ProductComponent.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};

export default connect()(multilanguage(ProductComponent));
