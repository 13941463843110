import React, { useEffect, useState, useCallback } from "react";
import Header from "../../Wrapper/Header";
import Loader from "./Loader";
import FooterComponent from "./FooterComponent";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos } from "./photos";
import PropTypes from "prop-types";
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import Galeri from "../Home/GaleriComponent";
import { Helmet } from "react-helmet";
// import images from "./images";

const GalleryComponent = ({ strings }) => {
  const images = [
    {
      src: "/assets/images/sohoweb12-min.webp",
      category: strings["urunler"],
    },
    {
      src: "/assets/images/sohoweb20-min.webp",
      category: strings["urunler"],
    },
    {
      src: "/assets/images/sohoweb17-min.webp",
      category: strings["urunler"],
    },
    {
      src: "/assets/images/sohoweb16-min.webp",
      category: strings["urunler"],
    },
    {
      src: "/assets/images/soho_1.webp",
      category: strings["urunler"],
    },
    {
      src: "/assets/images/DSCF0663-min.webp",
      category: strings["urunler"],
    },
    {
      src: "/assets/images/DSCF0662-min.webp",
      category: strings["urunler"],
    },
    {
      src: "/assets/images/DSCF0659-min.webp",
      category: strings["urunler"],
    },
    {
      src: "/assets/images/DSCF0660-min.webp",
      category: strings["urunler"],
    },
    {
      src: "/assets/images/DSCF0661-min.webp",
      category: strings["urunler"],
    },
    {
      src: "/assets/images/DSCF0658-min.webp",
      category: strings["urunler"],
    },
    {
      src: "/assets/images/sohoweb3-min.webp",
      category: strings["urunler"],
    },
    {
      src: "/assets/images/sohoweb5-min.webp",
      category: strings["urunler"],
    },
    {
      src: "/assets/images/sohoweb7-min.webp",
      category: strings["urunler"],
    },
    {
      src: "/assets/images/sohoweb6-min.webp",
      category: strings["urunler"],
    },
    {
      src: "/assets/images/sohoweb2-min.webp",
      category: strings["sera"],
    },
    {
      src: "/assets/images/sohoweb1-min.webp",
      category: strings["urunler"],
    },
    {
      src: "/assets/images/sohoweb8-min.webp",
      category: strings["sera"],
    },
    {
      src: "/assets/images/sohoweb1-min.webp",
      category: strings["sera"],
    },
    {
      src: "/assets/images/sohoweb9-min.webp",
      category: strings["sera"],
    },

    {
      src: "/assets/images/sohoweb4-min.webp",
      category: strings["sera"],
    },
    {
      src: "/assets/images/sohoweb14-min.webp",
      category: strings["urunler"],
    },
    {
      src: "/assets/images/sohoweb15-min.webp",
      category: strings["urunler"],
    },
    {
      src: "/assets/images/sohoweb13-min.webp",
      category: strings["urunler"],
    },
    {
      src: "/assets/images/sohoweb11-min.webp",
      category: strings["sera"],
    },
    {
      src: "/assets/images/sohoweb18-min.webp",
      category: strings["urunler"],
    },
    {
      src: "/assets/images/sohoweb21-min.webp",
      category: strings["sera"],
    },
    {
      src: "/assets/images/sohoweb22-min.webp",
      category: strings["urunler"],
    },
  ];
  let settings = {
    columnCount: {
      default: 3,
      mobile: 2,
      tab: 3,
    },
    mode: "dark",
  };
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <>
    <Helmet>
            <title>{`SOHO Fertilizer | ${strings["galeri"]}`}</title>
          <meta property="title" content={`SOHO Fertilizer | ${strings["galeri"]}`} />

        <meta property="og:type" content="article" />
        <meta property="og:title" content={`SOHO Fertilizer | ${strings["galeri"]}`}/>
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content={"https://www.sohofertilizer.com/gallery"}
        />

        <link
          rel="canonical"
          href={"https://www.sohofertilizer.com/gallery"}
        />
      
      </Helmet>
      <Loader loading={loading} />
      <div className="row justify-content-center py-0 h-10vh">
        <div className="zi justify-content-center position-fixed p-0">
          <div className="logoSoho" style={{ backgroundColor: "white" }}>
            <a href="/">
              <img
                className="logoSoho2"
                src="/assets/images/logoSoho.svg"
                alt="soho fertilizer damlama gübreleri demir organik"
              />
            </a>
          </div>
        </div>
      </div>

      <Header />
      <section id="portfolio" className="portfolio ">
        <div className="backImg2 d-flex justify-content-start padding-section-gallery">
          <div className="title d-flex align-items-center ">
            <span className="main-title p-3">{strings["galeri"]}</span>

            <span className="slash-icon white">/ </span>
            <span className="main-title p-3">SOHO </span>
            <span className="slash-icon white">/ </span>
            <img
              src="/assets/images/camera.png"
              className="banner_icon2 p-3"
              alt="soho fertilizer damlama gübreleri demir organik"
            />
          </div>
        </div>

        <div className="warpper">
          <input
            className="radio-m"
            id="one"
            name="group"
            type="radio"
            defaultChecked
          />
          <input className="radio-m" id="two" name="group" type="radio" />
          <div className="tabs-m">
            <h1
              style={{
                color: "white",
                margin: "0px",
                padding: "0px",
                fontSize: "1px",
              }}
            >
              Galeri
            </h1>
            <label className="tab-m" id="one-tab" htmlFor="one">
              {strings["foto"]}
            </label>
            <label className="tab-m" id="two-tab" htmlFor="two">
              {strings["video"]}
            </label>
          </div>
          <div className="panels-m">
            <div className="panel-m" id="one-panel">
              <div className="d-flex justify-content-center">
                {" "}
                <div className="col-lg-8 col-md-10 col-sm-10">
                  <Galeri
                    onClick={openLightbox}
                    className="portfolio-sorting list-inline "
                    id="gallery2"
                    settings={settings}
                    buttonFilters={[
                      strings["tumu"],
                      strings["urunler"],
                      strings["sera"],
                    ]}
                    galleryImages={images}
                  />
                </div>
              </div>
            </div>
            <div className="panel-m mt-5" id="two-panel">
              <div className="row d-flex justify-content-center">
                <div className="col-lg-3 col-md-6 col-sm-10">
                  {" "}
                  <video
                    className="video-h"
                    controls
                    poster="/assets/images/poster3.webp"
                  >
                    <source
                      src="/assets/images/askinates.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>{" "}
                <div className="col-lg-3  col-md-10 col-sm-10">
                  {" "}
                  <video
                    className="video-h"
                    controls
                    poster="/assets/images/poster1.webp"
                  >
                    <source
                      src="/assets/images/nadirokutucu.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>{" "}
                <div className="col-lg-3  col-md-6 col-sm-10">
                  {" "}
                  <video
                    className="video-h"
                    controls
                    poster="/assets/images/poster2.webp"
                  >
                    <source
                      src="/assets/images/erolkurban.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>{" "}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="d-flex justify-content-center">
          {" "}
          <div className="col-lg-8 col-md-10 col-sm-10">
            <Galeri
              onClick={openLightbox}
              className="portfolio-sorting list-inline "
              id="gallery2"
              settings={settings}
              buttonFilters={["All", "Products", "Greenhouse"]}
              galleryImages={images}
            />
          </div>
        </div> */}
      </section>
      <FooterComponent />
    </>
  );
};

GalleryComponent.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};

export default connect()(multilanguage(GalleryComponent));
