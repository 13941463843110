import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const ProductSlider = () => {
  useEffect(() => {
    // Update the document title using the browser API
    var radius = 240;
    var autoRotate = true;
    var rotateSpeed = -60;
    // var imgWidth = 120;
    // var imgHeight = 170;
    var aWidth = 120;
    var aHeight = 170;
    setTimeout(init, 1000);
    var odrag = document.getElementById("drag-container");
    var ospin = document.getElementById("spin-container");
    var aVid = ospin.getElementsByTagName("video");
    var aTag = ospin.getElementsByTagName("a");

    var aEle = [...aVid, ...aTag];

    // Size of a tag
    ospin.style.width = aWidth + "px";
    ospin.style.height = aHeight + "px";

    // Size of ground - depend on radius
    var ground = document.getElementById("ground");
    ground.style.width = radius * 3 + "px";
    ground.style.height = radius * 3 + "px";

    function init(delayTime) {
      for (var i = 0; i < aEle.length; i++) {
        aEle[i].style.transform =
          "rotateY(" +
          i * (360 / aEle.length) +
          "deg) translateZ(" +
          radius +
          "px)";
        aEle[i].style.transition = "transform 1s";
        aEle[i].style.transitionDelay =
          delayTime || (aEle.length - i) / 4 + "s";
      }
    }

    function applyTranform(obj) {
      // Constrain the angle of camera (between 0 and 180)
      if (tY > 180) tY = 180;
      if (tY < 0) tY = 0;

      // Apply the angle
      obj.style.transform = "rotateX(" + -tY + "deg) rotateY(" + tX + "deg)";
    }

    function playSpin(yes) {
      ospin.style.animationPlayState = yes ? "running" : "paused";
    }

    var sX,
      sY,
      nX,
      nY,
      desX = 0,
      desY = 0,
      tX = 0,
      tY = 10;

    // auto spin
    if (autoRotate) {
      var animationName = rotateSpeed > 0 ? "spin" : "spinRevert";
      ospin.style.animation = `${animationName} ${Math.abs(
        rotateSpeed
      )}s infinite linear`;
    }

    // setup events
    document.onpointerdown = function (e) {
      clearInterval(odrag.timer);
      e = e || window.event;
      var sX = e.clientX,
        sY = e.clientY;

      this.onpointermove = function (e) {
        e = e || window.event;
        var nX = e.clientX,
          nY = e.clientY;
        desX = nX - sX;
        desY = nY - sY;
        tX += desX * 0.1;
        tY += desY * 0.1;
        applyTranform(odrag);
        sX = nX;
        sY = nY;
      };

      this.onpointerup = function (e) {
        odrag.timer = setInterval(function () {
          desX *= 0.95;
          desY *= 0.95;
          tX += desX * 0.1;
          tY += desY * 0.1;
          applyTranform(odrag);
          playSpin(false);
          if (Math.abs(desX) < 0.5 && Math.abs(desY) < 0.5) {
            clearInterval(odrag.timer);
            playSpin(true);
          }
        }, 17);
        this.onpointermove = this.onpointerup = null;
      };

      return false;
    };

    document.onmousewheel = function (e) {
      e = e || window.event;
      var d = e.wheelDelta / 20 || -e.detail;
      radius += d;
      init(1);
    };
  });
  return (
    <div>
      {" "}
      <div className="product-body">
        {" "}
        <div id="drag-container">
          <div id="spin-container">
            {/* Add your images (or video) here */}
            <Link to="/products/SOHO%2015-30-15">
              <img
                src="/assets/images/products_gif/153015.gif"
                alt="soho fertilizer damlama gübreleri demir organik"
              ></img>
            </Link>
            <Link to="/products/SOHO%2016-8-24">
              {" "}
              <img
                src="/assets/images/products_gif/16824.gif"
                alt="soho fertilizer damlama gübreleri demir organik"
              />{" "}
            </Link>
            <Link to="/products/SOHO%2017-17-17">
              {" "}
              <img
                src="/assets/images/products_gif/171717.gif"
                alt="soho fertilizer damlama gübreleri demir organik"
              />{" "}
            </Link>
            <Link to="/products/SOHO%2016-5-30">
              {" "}
              <img
                src="/assets/images/products_gif/16530.gif"
                alt="soho fertilizer damlama gübreleri demir organik"
              />{" "}
            </Link>
            <Link to="/products/SOHO%2015-51-0">
              <img
                src="/assets/images/products_gif/15510_1.gif"
                alt="soho fertilizer damlama gübreleri demir organik"
              />{" "}
            </Link>
            {/* Example image with link */}
            <Link to="/products/SOHO%204-30-30">
              <img
                src="/assets/images/products_gif/43030.gif"
                alt="soho fertilizer damlama gübreleri demir organik"
              />
            </Link>

            <Link to="/products/SOHO%2010-0-36">
              <img
                src="/assets/images/products_gif/10036_.gif"
                alt="soho fertilizer damlama gübreleri demir organik"
              />
            </Link>
            <Link to="/products/SOHO%2017-7-21">
              <img
                src="/assets/images/products_gif/17721.png"
                alt="soho fertilizer damlama gübreleri demir organik"
              />
            </Link>
            <Link to="/products/SOHO%2018-6-19">
              <img
                src="/assets/images/products_gif/18619.png"
                alt="soho fertilizer damlama gübreleri demir organik"
              />
            </Link>
           
            <img
              className="product-s"
              src="/assets/images/logoSoho.svg"
              alt="soho demir organik"
            ></img>
          </div>
          <div id="ground" />
        </div>
      </div>
    </div>
  );
};

export default ProductSlider;
