import React, { Component } from "react";
import Header from "../Wrapper/Header";
import DashAbout from "../Component/Home/DashAbout";
import FooterComponent from "../Component/Home/FooterComponent";
import ContactComponent from "../Component/Home/ContactComponent";
import VideoComponent from "../Component/Home/VideoComponent";
import CarouselComponent from "../Component/Home/CarouselComponent";
import { PropTypes } from "prop-types";
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Parallax, Navigation } from "swiper";
import { jarallax, jarallaxVideo } from 'jarallax';
import 'jarallax/dist/jarallax.min.css';

// Optional video extension
jarallaxVideo();

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = { height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({ height: window.innerHeight });
  }
  componentDidMount() {
    jarallax(document.querySelectorAll('.jarallax'), {
      speed: 0.2,
      videoSrc: 'https://www.youtube.com/watch?v=iMDQr-qF3wg',
    });
    jarallax(document.querySelectorAll('.jarallax-2'), {
      speed: 0.2,
      videoSrc: 'https://www.youtube.com/watch?v=SKy_NRwiIzg',
    });

    jarallax(document.querySelectorAll('.jarallax-3'), {
      speed: 0.2,
      videoSrc: 'https://www.youtube.com/watch?v=LdgEr6yXwt8',
    });
    jarallax(document.querySelectorAll('.jarallax-4'), {
      speed: 0.2,
      videoSrc: 'https://www.youtube.com/watch?v=GKuY0dvBBjo',
    });

  
    // setTimeout(() => {
    //   document.getElementById("checkbox").checked = true;
    // }, 2500);

    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  render() {
    return (
      <>
      <Helmet>
            <title>SOHO Fertilizer </title>
          <meta property="title" content="SOHO Fertilizer" />

        <meta property="og:type" content="article" />
        <meta property="og:title" content="SOHO Fertilizer"/>
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content={"https://www.sohofertilizer.com"}
        />

        <link
          rel="canonical"
          href={"https://www.sohofertilizer.com"}
        />
      
      </Helmet>
        <div className="row justify-content-center py-0">
          <div className="zi justify-content-center position-fixed p-0">
            <div className="logoSoho" style={{ backgroundColor: "white" }}>
              <a href="/">
                <img
                  className="logoSoho2"
                  src="/assets/images/logoSoho.svg"
                  style={{ width: "200px", height: "75px" }}
                  alt="soho fertilizer damlama gübreleri demir organik"
                />
              </a>
            </div>
          </div>
        </div>
        {/* <SliderComponent /> */}
        <div className="lg">
          <Swiper
            style={{
              backgroundColor: "rgb(227, 227, 227)",
              height: this.state.height - 60 + "px",
            }}
            speed={1500}
            loop={true}
            parallax={true}
            autoplay={{
              delay: 6000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Parallax, Pagination, Navigation]}
          >
            <SwiperSlide>
              <div className="container_2">
              <div class="jarallax" data-jarallax data-video-src="https://www.youtube.com/watch?v=iMDQr-qF3wg">
</div>
                <div class="overlay-desc">
                  <h1>
                    {this.props.strings["yeni_nesil"]}

                    <div className="blue2">
                      {" "}
                      {this.props.strings["organomineral"]}{" "}
                    </div>

                    {this.props.strings["damlama_gubre"]}
                  </h1>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="container_2">
              <div class="jarallax-2" data-jarallax data-video-src="https://www.youtube.com/watch?v=SKy_NRwiIzg">
</div>
                <div class="overlay-desc">
                  <h1>
                    {this.props.strings["inavasyon"]}
                    <span className="and"> &</span>
                    <div className="blue2">
                      {" "}
                      {this.props.strings["benzersiz"]}{" "}
                    </div>
                    <span className="and"></span>
                    {this.props.strings["teknoloji"]}
                  </h1>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="container_2">
              <div class="jarallax-3" data-jarallax data-video-src="https://www.youtube.com/watch?v=LdgEr6yXwt8">
</div>
                <div class="overlay-desc">
                  <h1>
                    {" "}
                    <div className="white">
                      {" "}
                      {this.props.strings["organik_madde"]}
                    </div>
                    <div className="blue2">
                      {" "}
                      {this.props.strings["humik_fulvik"]}
                    </div>
                    <div className="white">
                      {" "}
                      {this.props.strings["organikazot"]}
                    </div>
                    <div className="blue2">
                      {" "}
                      {this.props.strings["highnpk"]}
                    </div>
                  </h1>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="container_2">
              <div class="jarallax-4" data-jarallax data-video-src="https://www.youtube.com/watch?v=GKuY0dvBBjo">
</div>
                <div class="overlay-desc">
                  <h1>
                    {this.props.strings["tamamen"]}
                    <div className="blue2">{this.props.strings["suda"]}</div>
                    {this.props.strings["cozunur"]}
                  </h1>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="container_2">
                <video playsInline autoPlay muted loop>
                  <source
                    src="/assets/images/drone_slider_loop_dusuk.mp4"
                    type="video/mp4"
                  />
                </video>
                <div class="overlay-desc">
                  <h1>
                    {this.props.strings["high_tech"]}
                    <div className="blue2">
                      {this.props.strings["essiz_form"]}
                    </div>
                  </h1>
                </div>
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
              <div
                className="our-team"
                style={{
                  height: this.state.height - 60 + "px",
                }}
              >
                {" "}
                <div className="row ">
                  <div className="container " style={{ marginTop: "300px" }}>
                    <div className="menu">
                      <input className="toggle" id="checkbox" type="checkbox" />
                      <label htmlFor="menu">
                        <div className=" col-lg-12 mx-0 px-0 d-flex justify-content-center align-items-end">
                          <img
                            aria-hidden="true"
                            className="dashImg"
                            src="/assets/images/soho_3.gif"
                            alt=""
                          />
                        </div>
                      </label>
                      <a className="tab" href="/products/SOHO 10-0-36">
                        <img
                          className=" aw-zoom "
                          src="/assets/images/products_gif/10036_.gif"
                          alt=""
                        ></img>
                      </a>

                      <a className="tab" href="/products/SOHO 16-5-30">
                        <img
                          className="aw-zoom"
                          src="/assets/images/products_gif/16530.gif"
                          alt=""
                        ></img>
                      </a>

                      <a className="tab" href="/products/SOHO 16-8-24">
                        <img
                          className=" aw-zoom"
                          src="/assets/images/products_gif/16824.gif"
                          alt=""
                        ></img>
                      </a>
                      <a className="tab" href="/products/SOHO 4-30-30">
                        <img
                          className=" aw-zoom"
                          src="/assets/images/products_gif/43030.gif"
                          alt=""
                        ></img>
                      </a>
                      <a className="tab" href="/products/SOHO 15-30-15">
                        <img
                          className=" aw-zoom"
                          src="/assets/images/products_gif/153015.gif"
                          alt=""
                        ></img>
                      </a>
                      <a className="tab" href="/products/SOHO 15-51-0">
                        <img
                          className=" aw-zoom"
                          src="/assets/images/43030_000000.gif"
                          alt=""
                        ></img>
                      </a>
                      <a className="tab" href="/products/SOHO 17-17-17">
                        <img
                          className=" aw-zoom"
                          src="/assets/images/products_gif/171717.gif"
                          alt=""
                        ></img>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide> */}

            {/* <SwiperSlide>
            <div className="container-flex our-team ">
              <div className="row">
                <div>
                  <div className="container" style={{ marginTop: "300px" }}>
                    <div className="menu">
                      <input className="toggle" id="checkbox" type="checkbox" />
                      <label htmlFor="menu">
                        <div className=" col-lg-12 mx-0 px-0 d-flex justify-content-center align-items-end">
                          <img
                            aria-hidden="true"
                            className="dashImg"
                            src="/assets/images/soho_3.gif"
                            alt=""
                          />
                        </div>
                      </label>
                      <a className="tab" href="/products/SOHO 10-0-36">
                        <img
                          className=" aw-zoom "
                          src="/assets/images/products_gif/10036_.gif"
                          alt=""
                        ></img>
                      </a>

                      <a className="tab" href="/products/SOHO 16-5-30">
                        <img
                          className="aw-zoom"
                          src="/assets/images/products_gif/16530.gif"
                          alt=""
                        ></img>
                      </a>

                      <a className="tab" href="/products/SOHO 16-8-24">
                        <img
                          className=" aw-zoom"
                          src="/assets/images/products_gif/16824.gif"
                          alt=""
                        ></img>
                      </a>
                      <a className="tab" href="/products/SOHO 4-30-30">
                        <img
                          className=" aw-zoom"
                          src="/assets/images/products_gif/43030.gif"
                          alt=""
                        ></img>
                      </a>
                      <a className="tab" href="/products/SOHO 15-30-15">
                        <img
                          className=" aw-zoom"
                          src="/assets/images/products_gif/153015.gif"
                          alt=""
                        ></img>
                      </a>
                      <a className="tab" href="/products/SOHO 15-51-0">
                        <img
                          className=" aw-zoom"
                          src="/assets/images/43030_000000.gif"
                          alt=""
                        ></img>
                      </a>
                      <a className="tab" href="/products/SOHO 17-17-17">
                        <img
                          className=" aw-zoom"
                          src="/assets/images/products_gif/171717.gif"
                          alt=""
                        ></img>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide> */}
          </Swiper>
        </div>
        <div className="md-sm">
          <Swiper
            style={{
              backgroundColor: "rgb(227, 227, 227)",
              height: this.state.height - 60 + "px",
            }}
            speed={1500}
            loop={true}
            parallax={true}
            autoplay={{
              delay: 6000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Parallax, Pagination, Navigation]}
          >
            <SwiperSlide>
              <div className="container_2">
                <img
                  style={{ height: "100vh" }}
                  src="/assets/images/slider1.webp"
                  alt="soho fertilizer damlama gübreleri demir organik"
                ></img>
                <div class="overlay-desc">
                  <h1>
                    {this.props.strings["yeni_nesil"]}

                    <div className="blue2">
                      {" "}
                      {this.props.strings["organomineral"]}{" "}
                    </div>

                    {this.props.strings["damlama_gubre"]}
                  </h1>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="container_2">
                <img
                  style={{ height: "100vh" }}
                  src="/assets/images/slider2.webp"
                  alt="soho fertilizer damlama gübreleri demir organik"
                ></img>
                <div class="overlay-desc">
                  <h1>
                    {this.props.strings["inavasyon"]}
                    <span className="and"> &</span>
                    <div className="blue2">
                      {" "}
                      {this.props.strings["benzersiz"]}{" "}
                    </div>
                    <span className="and"></span>
                    {this.props.strings["teknoloji"]}
                  </h1>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="container_2">
                <img
                  style={{ height: "100vh" }}
                  src="/assets/images/slider3.webp"
                  alt="soho fertilizer damlama gübreleri demir organik"
                ></img>
                <div class="overlay-desc">
                  <h1>
                    {" "}
                    <div className="white">
                      {" "}
                      {this.props.strings["organik_madde"]}
                    </div>
                    <div className="blue2">
                      {" "}
                      {this.props.strings["humik_fulvik"]}
                    </div>
                    <div className="white">
                      {" "}
                      {this.props.strings["organikazot"]}
                    </div>
                    <div className="blue2">
                      {" "}
                      {this.props.strings["highnpk"]}
                    </div>
                  </h1>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="container_2">
                <img
                  style={{ height: "100vh" }}
                  src="/assets/images/slider4.webp"
                  alt="soho fertilizer damlama gübreleri demir organik"
                ></img>
                <div class="overlay-desc">
                  <h1>
                    {this.props.strings["tamamen"]}
                    <div className="blue2">{this.props.strings["suda"]}</div>
                    {this.props.strings["cozunur"]}
                  </h1>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="container_2">
                <img
                  style={{ height: "100vh" }}
                  src="/assets/images/soho_drone.webp"
                  alt="soho fertilizer damlama gübreleri demir organik"
                ></img>
                <div class="overlay-desc">
                  <h1>
                    {this.props.strings["high_tech"]}
                    <div className="blue2">
                      {this.props.strings["essiz_form"]}
                    </div>
                  </h1>
                </div>
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
              <div
                className="our-team"
                style={{
                  height: this.state.height - 60 + "px",
                }}
              >
                {" "}
                <div className="row ">
                  <div className="container " style={{ marginTop: "300px" }}>
                    <div className="menu">
                      <input className="toggle" id="checkbox" type="checkbox" />
                      <label htmlFor="menu">
                        <div className=" col-lg-12 mx-0 px-0 d-flex justify-content-center align-items-end">
                          <img
                            aria-hidden="true"
                            className="dashImg"
                            src="/assets/images/soho_3.gif"
                            alt=""
                          />
                        </div>
                      </label>
                      <a className="tab" href="/products/SOHO 10-0-36">
                        <img
                          className=" aw-zoom "
                          src="/assets/images/products_gif/10036_.gif"
                          alt=""
                        ></img>
                      </a>

                      <a className="tab" href="/products/SOHO 16-5-30">
                        <img
                          className="aw-zoom"
                          src="/assets/images/products_gif/16530.gif"
                          alt=""
                        ></img>
                      </a>

                      <a className="tab" href="/products/SOHO 16-8-24">
                        <img
                          className=" aw-zoom"
                          src="/assets/images/products_gif/16824.gif"
                          alt=""
                        ></img>
                      </a>
                      <a className="tab" href="/products/SOHO 4-30-30">
                        <img
                          className=" aw-zoom"
                          src="/assets/images/products_gif/43030.gif"
                          alt=""
                        ></img>
                      </a>
                      <a className="tab" href="/products/SOHO 15-30-15">
                        <img
                          className=" aw-zoom"
                          src="/assets/images/products_gif/153015.gif"
                          alt=""
                        ></img>
                      </a>
                      <a className="tab" href="/products/SOHO 15-51-0">
                        <img
                          className=" aw-zoom"
                          src="/assets/images/43030_000000.gif"
                          alt=""
                        ></img>
                      </a>
                      <a className="tab" href="/products/SOHO 17-17-17">
                        <img
                          className=" aw-zoom"
                          src="/assets/images/products_gif/171717.gif"
                          alt=""
                        ></img>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide> */}

            {/* <SwiperSlide>
            <div className="container-flex our-team ">
              <div className="row">
                <div>
                  <div className="container" style={{ marginTop: "300px" }}>
                    <div className="menu">
                      <input className="toggle" id="checkbox" type="checkbox" />
                      <label htmlFor="menu">
                        <div className=" col-lg-12 mx-0 px-0 d-flex justify-content-center align-items-end">
                          <img
                            aria-hidden="true"
                            className="dashImg"
                            src="/assets/images/soho_3.gif"
                            alt=""
                          />
                        </div>
                      </label>
                      <a className="tab" href="/products/SOHO 10-0-36">
                        <img
                          className=" aw-zoom "
                          src="/assets/images/products_gif/10036_.gif"
                          alt=""
                        ></img>
                      </a>

                      <a className="tab" href="/products/SOHO 16-5-30">
                        <img
                          className="aw-zoom"
                          src="/assets/images/products_gif/16530.gif"
                          alt=""
                        ></img>
                      </a>

                      <a className="tab" href="/products/SOHO 16-8-24">
                        <img
                          className=" aw-zoom"
                          src="/assets/images/products_gif/16824.gif"
                          alt=""
                        ></img>
                      </a>
                      <a className="tab" href="/products/SOHO 4-30-30">
                        <img
                          className=" aw-zoom"
                          src="/assets/images/products_gif/43030.gif"
                          alt=""
                        ></img>
                      </a>
                      <a className="tab" href="/products/SOHO 15-30-15">
                        <img
                          className=" aw-zoom"
                          src="/assets/images/products_gif/153015.gif"
                          alt=""
                        ></img>
                      </a>
                      <a className="tab" href="/products/SOHO 15-51-0">
                        <img
                          className=" aw-zoom"
                          src="/assets/images/43030_000000.gif"
                          alt=""
                        ></img>
                      </a>
                      <a className="tab" href="/products/SOHO 17-17-17">
                        <img
                          className=" aw-zoom"
                          src="/assets/images/products_gif/171717.gif"
                          alt=""
                        ></img>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide> */}
          </Swiper>
        </div>
        <Header />
        <DashAbout />
        <div className="">
          <div class="container-4">
            <div class="drop" style={{ color: "#23438c" }}>
              <div class="content-4">
                <h2>
                  {" "}
                  <img
                    src="/assets/images/su_2.png"
                    alt="soho fertilizer damlama gübreleri demir organik"
                  ></img>
                </h2>
                <p>{this.props.strings["damla_1"]}</p>
                <a href="/about">
                  <Button className="button-dash">
                    {this.props.strings["hakkkimizda_3"]}
                  </Button>
                </a>
              </div>
            </div>
            <div class="drop" style={{ color: "#72c8df" }}>
              <div class="content-4">
                <h2>
                  {" "}
                  <img
                    src="/assets/images/urun.png"
                    alt="soho fertilizer damlama gübreleri demir organik"
                  ></img>
                </h2>
                <p>{this.props.strings["damla_2"]}</p>
                <a href="/products">
                  <Button className="button-dash">
                    {this.props.strings["urunler"]}
                  </Button>
                </a>
              </div>
            </div>
            <div class="drop" style={{ color: "#4e7ba2" }}>
              <div class="content-4">
                <h2>
                  {" "}
                  <img
                    src="/assets/images/13.png"
                    alt="soho fertilizer damlama gübreleri demir organik"
                  ></img>
                </h2>
                <p>{this.props.strings["damla_3"]}</p>
                <a href="/contact">
                  <Button className="button-dash">
                    {this.props.strings["iletisim"]}
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="grid-row mt-5">
          <div className="grid-col-row clear">
            <div className="col-xl-12 text-xl-left text-center mb-xl-0 mb-3 ">
              <dl className="container p-0 d-flex justify-content-center">
                <div className="row d-flex justify-content-center">
                  <dd
                    className="about4 col-9 d-flex justify-content-center"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                    data-aos-delay="00"
                  >
                    "{this.props.strings["home_1"]}"
                  </dd>
                  <dd
                    className="about5 col-9"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                    data-aos-delay="100"
                  >
                    "{this.props.strings["home_2"]}"
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
        <div className="sohoGif-lg">
          {" "}
          <div className="container d-flex justify-content-center">
            <img
              className="soho_urun"
              src="/assets/images/sohogif.webp"
              alt="soho fertilizer damlama gübreleri demir organik"
            ></img>
          </div>
        </div>

        <div className="sohoGif">
          <div className="container d-flex justify-content-center">
            <img
              className="soho_urun"
              src="/assets/images/sohogif.webp"
              alt="soho fertilizer damlama gübreleri demir organik"
            ></img>
          </div>
        </div>

        {this.props.currentLanguageCode === "en" ? (
          <div data-mc-src="16c558f1-ed4f-4d8c-8609-7e24d836e159#instagram"></div>
        ) : (
          <div data-mc-src="40a4dd92-fd23-4d33-ab24-5e6c7d95f68b#instagram"></div>
        )}

        <div className="white-page"></div>
        <ContactComponent />
        <FooterComponent />
        <div className="social-top">
          <div className="grid-row clear ">
            <div className="social">
              <a
                href="https://www.linkedin.com/company/demir-organik-tar%C4%B1m-ltd-%C5%9Fti/"
                target="_blank"
                rel="noreferrer"
                aria-label="linkedin"
              >
                <div className="contact-round">
                  <i className="fa fa-linkedin" />
                </div>
              </a>
              <a
                href="https://www.facebook.com/demirorganiktarim/"
                aria-label="facebook"
                target="_blank"
                rel="noreferrer"
              >
                <div className="contact-round">
                  <i className="fa fa-facebook" />
                </div>
              </a>
              <a
                href="https://www.instagram.com/demirorganik/"
                aria-label="instagram"
                target="_blank"
                rel="noreferrer"
              >
                <div className="contact-round">
                  <i className="fa fa-instagram" />
                </div>
              </a>
              <a
                href="https://www.youtube.com/channel/UCJpY5g1TIOjNtz2YPWCZiHQ"
                aria-label="youtube"
                target="_blank"
                rel="noreferrer"
              >
                <div className="contact-round">
                  <i className="fa fa-youtube" />
                </div>
              </a>
            </div>
          </div>
        </div>
        <a href="#" id="scroll-top" className="scroll-top">
          <i className="fa fa-angle-up" />
        </a>
      </>
    );
  }
}
Dashboard.propTypes = {
  strings: PropTypes.object,
};

export default connect()(multilanguage(Dashboard));
