import React, { Component } from "react";
import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Button from "react-bootstrap/Button";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import { PropTypes } from "prop-types";
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";

class Example extends Component {
  state = {
    goToSlide: 0,
    offsetRadius: 2,
    showNavigation: true,
    config: config.gentle,
  };

  slides = [
    {
      key: 1,
      content: (
        <video
          width={560}
          height={315}
          controls
          poster="/assets/images/poster3.webp"
        >
          <source src="/assets/images/askinates.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ),
    },
    {
      key: 2,
      content: (
        <video
          width={560}
          height={315}
          controls
          poster="/assets/images/poster2.webp"
        >
          <source src="/assets/images/erolkurban.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ),
    },
    {
      key: 3,
      content: (
        <video
          width={560}
          height={315}
          controls
          poster="/assets/images/poster1.webp"
        >
          <source src="/assets/images/nadirokutucu.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ),
    },
    {
      key: 4,
      content: (
        <video
          width={560}
          height={315}
          controls
          poster="/assets/images/poster4.webp"
        >
          <source src="/assets/images/mustafaakilli.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ),
    },
    {
      key: 5,
      content: (
        <video
          width={560}
          height={315}
          controls
          poster="/assets/images/poster6.webp"
        >
          <source src="/assets/images/ahmetyilmaz.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ),
    },
    {
      key: 6,
      content: (
        <video
          width={560}
          height={315}
          controls
          poster="/assets/images/poster7.webp"
        >
          <source src="/assets/images/serefkireci.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ),
    },
    {
      key: 7,
      content: (
        <video
          width={560}
          height={315}
          controls
          poster="/assets/images/poster5.webp"
        >
          <source src="/assets/images/ibrahimalkan.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ),
    },
  ].map((slide, index) => {
    return { ...slide, onClick: () => this.setState({ goToSlide: index }) };
  });

  render() {
    return (
      <>
        {" "}
        <div className="product-soho">
          <div className="d-flex justify-content-center uretici-margin">
            <h1> {this.props.strings["uretici_video"]}</h1>
          </div>
          <div className="three_slider">
            <Carousel
              slides={this.slides}
              goToSlide={this.state.goToSlide}
              offsetRadius={this.state.offsetRadius}
              showNavigation={this.state.showNavigation}
              animationConfig={this.state.config}
            />
            <div className="d-flex justify-content-center mt-3">
              <ArrowBackIos
                className="forwardandbackicons icon-mobile-2"
                onClick={() => {
                  this.setState({ goToSlide: this.state.goToSlide - 1 });
                }}
              />
              &nbsp; &nbsp; &nbsp; &nbsp;
              <ArrowForwardIosIcon
                className="forwardandbackicons icon-mobile-2"
                onClick={() => {
                  this.setState({ goToSlide: this.state.goToSlide + 1 });
                }}
              />
            </div>
          </div>
        </div>{" "}
        <div className="d-flex justify-content-center">
          {" "}
          <a
            href="https://www.youtube.com/@demirorganiktarim"
            target={"_blank"}
            rel="noreferrer"
          >
            {" "}
            <Button
              className="form-submit rectangle-button red medium"
              name="submit"
              type="submit"
              id="submit"
            >
              {this.props.strings["youtube_button"]}
              <i className="fa fa-youtube-play " />
            </Button>
            <h1
              style={{
                color: "white",
                margin: "0px",
                padding: "0px",
                fontSize: "1px",
              }}
            >
              Hakkımızda
            </h1>
          </a>
        </div>
      </>
    );
  }
}
Example.propTypes = {
  strings: PropTypes.object,
};

export default connect()(multilanguage(Example));
