import React, { useEffect, useState } from "react";
import Header from "../Wrapper/Header";
import Loader from "../Component/Home/Loader";
import FooterComponent from "../Component/Home/FooterComponent";
import BlogComponent from "../Component/Home/BlogComponent";
import AboutSliderComponent from "../Component/Home/AboutSliderComponent";
import Test from "../Component/Home/test";
import Test2 from "../Component/Home/test2";
import PropTypes from "prop-types";
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

const AboutComponent = (props) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 800);
    var parent = document.querySelector(".splitview"),
      topPanel = parent.querySelector(".top"),
      handle = parent.querySelector(".handle"),
      skewHack = 0,
      delta = 0;
    if (parent.className.indexOf("skewed") != -1) {
      skewHack = 1000;
    }

    parent.addEventListener("mousemove", function (event) {
      delta = (event.clientX - window.innerWidth / 2) * 0.5;
      handle.style.left = event.clientX + delta + "px";
      topPanel.style.width = event.clientX + skewHack + delta + "px";
    });
  }, []);

  return (
    <>
    <Helmet>
            <title>{`SOHO Fertilizer | ${props.strings["hakkkimizda_2"]}`}</title>
          <meta property="title" content={`SOHO Fertilizer | ${props.strings["hakkkimizda_2"]}`} />

        <meta property="og:type" content="article" />
        <meta property="og:title" content={`SOHO Fertilizer | ${props.strings["hakkkimizda_2"]}`}/>
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content={"https://www.sohofertilizer.com/about"}
        />

        <link
          rel="canonical"
          href={"https://www.sohofertilizer.com/about"}
        />
      
      </Helmet>
      <Loader loading={loading} />
      <div className="row justify-content-center py-0 h-10vh">
        <div className="zi justify-content-center position-fixed p-0">
          <div className="logoSoho" style={{ backgroundColor: "white" }}>
            <a href="/">
              <img
                className="logoSoho2"
                src="/assets/images/logoSoho.svg"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
      <Header />
      <section id="about" className="about-us">
        <div className="backImg d-flex justify-content-start">
          <div className="title d-flex align-items-center ">
            <span className="main-title p-3">
              {props.strings["hakkkimizda_2"]}
            </span>
            <span className="slash-icon white p-3">/ </span>
            <div>
              <img src="/assets/images/su.png" className="banner_icon" alt="" />
            </div>
          </div>
        </div>
        <div className="grid-row mt-5">
          <div className="grid-col-row clear">
            <div className="col-xl-12 text-xl-left text-center mb-xl-0 mb-3 ">
              <dl className="container p-0 d-flex justify-content-center">
                <div className="row d-flex justify-content-center">
                  <dd className="about2 col-9 d-flex justify-content-center">
                    {props.strings["hakkimizda_text1"]}
                  </dd>

                  <dd className="about2 col-9">
                    {props.strings["hakkimizda_text2"]}
                  </dd>
                  <div className="col-10">
                    <AboutSliderComponent />
                  </div>
                </div>
              </dl>
              <div className="container p-0 d-flex justify-content-center">
                <div className="row justify-content-center">
                  <div className="col-lg-4 col-md-5 col-sm-4">
                    <div className="tf-text-service wow fadeInUp">
                      <dd className="about2 container-fluid">
                        {props.strings["hakkimizda_text4"]}
                      </dd>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-5 col-sm-4">
                    <div className="tf-text-service wow fadeInUp">
                      <dd
                        className="about2 container-fluid
                      ,"
                      >
                        {props.strings["hakkimizda_text3"]}
                      </dd>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>{" "}
      <Test />
      <div class="splitview skewed mt-5">
        <div class="panel bottom2">
          <div class="content">
            <img src="/assets/images/3_siyah-min.webp" alt="Original" />
          </div>
        </div>

        <div class="panel top">
          <div class="content">
            <img src="/assets/images/3-min.webp" alt="Duotone" />
          </div>
        </div>

        <div class="handle"></div>
      </div>
      <div className="img_comp_copntainer container-fluid ">
        <img-comparison-slider className="img_comp">
          <img
            className="col-12"
            slot="first"
            src="/assets/images/3-min.webp"
            alt=""
          />
          <img
            className="col-12"
            slot="second"
            src="/assets/images/3_siyah-min.webp"
            alt=""
          />
        </img-comparison-slider>
      </div>
      <div className="container-fluid d-flex justify-content-center services ">
        <img
          alt=""
          className="about-gif"
          src={
            props.currentLanguageCode === "en"
              ? "/assets/images/soho_en.webp"
              : "/assets/images/soho_tr.webp"
          }
        />
      </div>
      <BlogComponent />
      <FooterComponent />
      <a href="#" id="scroll-top" className="scroll-top">
        <i className="fa fa-angle-up" />
      </a>
    </>
  );
};

AboutComponent.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};

export default connect()(multilanguage(AboutComponent));
