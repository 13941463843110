import React from "react";
import { NavLink, Link } from "react-router-dom";

import { changeLanguage } from "redux-multilanguage";
import PropTypes from "prop-types";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
const HeaderComponent = (props) => {
  return (
    <>
      <div className="sticky  bottom-zero">
        <div className="grid-row">
          <img
            className="splash"
            src="/assets/images/splash-01.png"
            alt="soho fertilizer demir organik damlama gübreleri"
          />
          <div className="logo">
            <Link to="/">
              <img
                src="/assets/images/logo.svg"
                alt="soho fertilizer demir organik damlama gübreleri"
                // style={{ width: "200px", height: "75px" }}
              />
            </Link>
          </div>
          <section class="p-menu1">
            <nav id="navbar" class="navigation" role="navigation">
              <input id="toggle1" type="checkbox" />
              <label class="hamburger1 p-2" for="toggle1">
                <div class="top"></div>
                <div class="meat"></div>
                <div class="bottom"></div>
              </label>

              <nav class="menu1">
                <NavLink
                  className={(navData) =>
                    navData.isActive
                      ? "nav-item nav-link active"
                      : "nav-item nav-link"
                  }
                  to="/home"
                >
                  {props.strings["anasayfa"]}
                </NavLink>
                <NavLink
                  className={(navData) =>
                    navData.isActive
                      ? "nav-item nav-link active"
                      : "nav-item nav-link"
                  }
                  to="/about"
                >
                  {props.strings["hakkimizda"]}
                </NavLink>

                <NavLink
                  className={(navData) =>
                    navData.isActive
                      ? "nav-item nav-link active dropup"
                      : "nav-item nav-link dropup"
                  }
                  to="/products"
                >
                  {props.strings["urunler"]}
                  {/* <div class="dropup-content ">
                    <Link to="/products/SOHO 15-51-0">SOHO 15-51-0</Link>
                    <Link to="/products/SOHO 16-8-24">SOHO 16-8-24</Link>{" "}
                    <Link to="/products/SOHO 17-17-17">SOHO 17-17-17</Link>{" "}
                    <Link to="/products/SOHO 16-5-30">SOHO 16-5-30</Link>{" "}
                    <Link to="/products/SOHO 15-30-15">SOHO 15-30-15</Link>
                    <Link to="/products/SOHO 4-30-30">SOHO 4-30-30</Link>
                    <Link to="/products/SOHO 10-0-36">SOHO 10-0-36</Link>
                  </div> */}
                </NavLink>
                <NavLink
                  className={(navData) =>
                    navData.isActive
                      ? "nav-item nav-link active"
                      : "nav-item nav-link"
                  }
                  to="/faq"
                >
                  {props.strings["sss"]}
                </NavLink>

                <NavLink
                  className={(navData) =>
                    navData.isActive
                      ? "nav-item nav-link active"
                      : "nav-item nav-link"
                  }
                  to="/gallery"
                >
                  {props.strings["galeri"]}
                </NavLink>

                <NavLink
                  className={(navData) =>
                    navData.isActive
                      ? "nav-item nav-link active"
                      : "nav-item nav-link"
                  }
                  to="/contact"
                >
                  {props.strings["iletisim"]}
                </NavLink>
                <NavLink
                  className={(navData) =>
                    navData.isActive
                      ? "nav-item nav-link active"
                      : "nav-item nav-link"
                  }
                  to="/kvkk"
                >
                  {props.strings["kvkk"]}
                </NavLink>
                <div className="d-flex justify-content-center mb-3 ">
                  <div
                    className="language"
                    role="radiogroup"
                    aria-labelledby="language-switcher1"
                  >
                    <div className="language__container--left language__container--fr">
                      <input
                        onChange={(e) => {
                          props.dispatch(changeLanguage(e.target.value));
                        }}
                        className="language__control"
                        type="radio"
                        id="language1-1"
                        aria-label="en"
                        name="en"
                        value={"en"}
                        checked={
                          props.currentLanguageCode == "en" ? true : false
                        }
                      />
                      <label className="language__label" htmlFor="language1-1">
                        EN<span className="hidden"></span>
                      </label>
                    </div>
                    <div className="language__container--right language__container--en">
                      <input
                        onChange={(e) => {
                          props.dispatch(changeLanguage(e.target.value));
                        }}
                        className="language__control"
                        type="radio"
                        id="language1-2"
                        aria-label="tr"
                        name="tr"
                        value={"tr"}
                        checked={
                          props.currentLanguageCode == "tr" ? true : false
                        }
                      />
                      <label className="language__label" htmlFor="language1-2">
                        TR<span className="hidden"> </span>
                      </label>
                    </div>
                  </div>
                </div>
              </nav>
            </nav>
          </section>
          <div className="navbar-responsive-100">
            <nav class="navbar navbar-expand-lg navbar-light ">
              <div class="container-fluid p-0">
                <a href="/" class="navbar-brand" aria-label="home">
                  &nbsp;
                </a>

                <div class="collapse navbar-collapse" id="navbarCollapse">
                  <div class="navbar-nav topnav">
                    <NavLink
                      className={(navData) =>
                        navData.isActive
                          ? "nav-item nav-link active"
                          : "nav-item nav-link"
                      }
                      to="/home"
                    >
                      {props.strings["anasayfa"]}
                    </NavLink>
                    <NavLink
                      className={(navData) =>
                        navData.isActive
                          ? "nav-item nav-link active"
                          : "nav-item nav-link"
                      }
                      to="/about"
                    >
                      {props.strings["hakkimizda"]}
                    </NavLink>
                    <NavLink
                      className={(navData) =>
                        navData.isActive
                          ? "nav-item nav-link active dropup"
                          : "nav-item nav-link dropup"
                      }
                      to="/products"
                    >
                      {props.strings["urunler"]}
                      {/* <div class="dropup-content ">
                        <Link to="/products/SOHO 15-51-0">SOHO 15-51-0</Link>
                        <Link to="/products/SOHO 16-8-24">
                          SOHO 16-8-24
                        </Link>{" "}
                        <Link to="/products/SOHO 17-17-17">SOHO 17-17-17</Link>{" "}
                        <Link to="/products/SOHO 16-5-30">SOHO 16-5-30</Link>{" "}
                        <Link to="/products/SOHO 15-30-15">SOHO 15-30-15</Link>
                        <Link to="/products/SOHO 4-30-30">SOHO 4-30-30</Link>
                        <Link to="/products/SOHO 10-0-36">SOHO 10-0-36</Link>
                      </div> */}
                    </NavLink>

                    <NavLink
                      className={(navData) =>
                        navData.isActive
                          ? "nav-item nav-link active"
                          : "nav-item nav-link"
                      }
                      to="/faq"
                    >
                      {props.strings["sss"]}
                    </NavLink>

                    <NavLink
                      className={(navData) =>
                        navData.isActive
                          ? "nav-item nav-link active"
                          : "nav-item nav-link"
                      }
                      to="/gallery"
                    >
                      {props.strings["galeri"]}
                    </NavLink>

                    <NavLink
                      className={(navData) =>
                        navData.isActive
                          ? "nav-item nav-link active"
                          : "nav-item nav-link"
                      }
                      to="/contact"
                    >
                      {props.strings["iletisim"]}
                    </NavLink>
                    <NavLink
                      className={(navData) =>
                        navData.isActive
                          ? "nav-item nav-link active"
                          : "nav-item nav-link"
                      }
                      to="/kvkk"
                    >
                      {props.strings["kvkk"]}
                    </NavLink>

                    <div
                      style={{ marginLeft: "40px" }}
                      className="language"
                      role="radiogroup"
                      aria-labelledby="language-switcher1"
                    >
                      <div className="language__container--left language__container--fr">
                        <input
                          onChange={(e) => {
                            props.dispatch(changeLanguage(e.target.value));
                          }}
                          className="language__control"
                          type="radio"
                          id="language1-1"
                          name="en"
                          value={"en"}
                          checked={
                            props.currentLanguageCode == "en" ? true : false
                          }
                        />
                        <label
                          className="language__label"
                          htmlFor="language1-1"
                        >
                          EN<span className="hidden"></span>
                        </label>
                      </div>
                      <div className="language__container--right language__container--en">
                        <input
                          onChange={(e) => {
                            props.dispatch(changeLanguage(e.target.value));
                          }}
                          className="language__control"
                          type="radio"
                          id="language1-2"
                          name="en"
                          value={"tr"}
                          checked={
                            props.currentLanguageCode == "tr" ? true : false
                          }
                        />
                        <label
                          className="language__label"
                          htmlFor="language1-2"
                        >
                          TR<span className="hidden"> </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>

      {/*/ sticky menu */}
    </>
  );
};

HeaderComponent.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};

export default connect()(multilanguage(HeaderComponent));
