import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./Component/Home/scrolltotop";
import Dashboard from "./Pages/Dashboard";
import Contact from "./Pages/Contact";

import AboutComponent from "./Pages/About";
import ProductComponent from "./Component/Home/ProductComponent";
import SSSComponent from "./Component/Home/SSSComponent";
import GalleryComponent from "./Component/Home/GalleryComponent";
import ProductHomeComponent from "./Component/Home/ProductHomeComponent";
import Test from "./Component/Home/test";
import KVKKComponent from "./Component/Home/KVKKComponent";
import NotFound from "./Component/Home/NotFound";
/* import PortfolioComponent from "./Component/Home/PortfolioComponent"; */

import { changeLanguage } from "redux-multilanguage";
import PropTypes from "prop-types";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
const App = (props) => {
  props.dispatch(
    loadLanguages({
      languages: {
        en: require("./Translation/English.json"),
        tr: require("./Translation/Turkish.json"),
      },
    })
  );
  useEffect(() => {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("https://cdnjs.cloudflare.com/ajax/libs/gsap/3.3.3/gsap.min.js");
    // <!-- Bootstrap Core JavaScript-->
    loadScript("https://unpkg.com/imagesloaded@4/imagesloaded.pkgd.min.js");

    loadScript(
      "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"
    );

    loadScript("https://unpkg.com/object-fit-images/dist/ofi.min.js");
  }, []);
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route exact path="/" element={<Dashboard />} />
          <Route exact path="*" element={<NotFound />} />

          <Route exact path="/home" element={<Dashboard />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/about" element={<AboutComponent />} />
          <Route exact path="/products" element={<ProductHomeComponent />} />

          <Route exact path="/products/:name" element={<ProductComponent />} />
          <Route exact path="/gallery" element={<GalleryComponent />} />
          <Route exact path="/faq" element={<SSSComponent />} />
          <Route exact path="/kvkk" element={<KVKKComponent />} />
          <Route exact path="/test" element={<Test />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};

App.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};

export default connect()(multilanguage(App));
