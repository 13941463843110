import "react-accessible-accordion/dist/fancy-example.css";
import React, { useEffect, useState } from "react";
import Header from "../../Wrapper/Header";
import Loader from "../Home/Loader";
import ContactComponent from "./ContactComponent";
import FooterComponent from "../Home/FooterComponent";
import Button from "react-bootstrap/Button";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import PropTypes from "prop-types";
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import "react-accessible-accordion/dist/fancy-example.css";
import { Helmet } from "react-helmet";

const SSSComponent = (props) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);

  return (
    <>
          <Helmet>
            <title>{`SOHO Fertilizer | ${props.strings["sikca_soru"]}`}</title>
          <meta property="title" content={`SOHO Fertilizer | ${props.strings["sikca_soru"]}`} />

        <meta property="og:type" content="article" />
        <meta property="og:title" content={`SOHO Fertilizer | ${props.strings["sikca_soru"]}`}/>
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content={"https://www.sohofertilizer.com/faq"}
        />

        <link
          rel="canonical"
          href={"https://www.sohofertilizer.com/faq"}
        />
      
      </Helmet>
      <Loader loading={loading} />
      <div className="row justify-content-center py-0 h-10vh">
        <div className="zi justify-content-center position-fixed p-0">
          <div className="logoSoho" style={{ backgroundColor: "white" }}>
            <a href="/">
              <img
                className="logoSoho2"
                src="/assets/images/logoSoho.svg"
                alt="soho fertilizer damlama gübreleri demir organik"
              />
            </a>
          </div>
        </div>
      </div>
      <section className="">
        <div className="backImg4 d-flex justify-content-start">
          <div className="title d-flex align-items-center ">
            <span className="main-title p-3">
              {props.strings["sikca_soru"]}
            </span>

            <span className="slash-icon p-3 white">/</span>

            <div>
              <img
                src="/assets/images/sss.png"
                className="banner_icon"
                alt="soho fertilizer damlama gübreleri demir organik"
              />
            </div>
          </div>
        </div>
      </section>{" "}
      <Header />
      <div className="row d-flex justify-content-center">
        <div className="col-md-2 col-sm-6 d-flex justify-content-center mb-3">
          <img
            src="/assets/images/woman.webp"
            className="img-fluid"
            alt="soho fertilizer damlama gübreleri demir organik"
          />
        </div>
      </div>
      <div className="container-fluid d-flex justify-content-center">
        <div className="col-md-8 col-sm-12 mb-5">
          <Accordion preExpanded={["a"]}>
            <AccordionItem uuid="a">
              <AccordionItemHeading>
                <AccordionItemButton>
                  {props.strings["soru_1"]}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>{props.strings["cevap_1"]}</p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  {props.strings["soru_2"]}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>{props.strings["cevap_2"]}</p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  {props.strings["soru_3"]}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>{props.strings["cevap_3"]}</p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  {props.strings["soru_4"]}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>{props.strings["cevap_4"]}</p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  {props.strings["soru_5"]}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>{props.strings["cevap_5"]}</p>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
      <h1
        style={{
          color: "white",
          margin: "0px",
          padding: "0px",
          fontSize: "1px",
        }}
      >
        {props.strings["sikca_soru"]}
      </h1>
      <ContactComponent />
      <FooterComponent />
    </>
  );
};

SSSComponent.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};

export default connect()(multilanguage(SSSComponent));
