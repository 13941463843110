import React from "react";
import { useState } from "react";
import Lightroom from "react-lightbox-gallery";
import PropTypes from "prop-types";
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";

function Gallery(props) {
  const { id, className, galleryImages, settings, buttonFilters } = props;
  const [images, setImages] = useState(galleryImages);
  // console.log(galleryImages);

  //   console.log(buttonFilters);
  const filterImages = (e, category) => {
    e.preventDefault();

    const buttons = document.getElementsByClassName("btn-filter"),
      totalButtons = buttons.length;
    // console.log(e.target);
    for (let i = 0; i < totalButtons; i++) {
      if (e.target.id == buttons[i].id) buttons[i].classList.add("active");
      else buttons[i].classList.remove("active");
    }

    if (category === props.strings["tumu"]) setImages(galleryImages);
    else
      setImages(
        galleryImages.filter((mediaImage) => mediaImage.category === category)
      );
  };

  const btnFilters = buttonFilters.map((filter, i) => (
    <button
      style={{ border: "none", margin: "10px", background: "none" }}
      value={filter}
    >
      <dl>
        {" "}
        <dd
          onClick={(e) => filterImages(e, filter)}
          id={filter}
          className={i === 0 ? "btn-filter active" : "btn-filter"}
        >
          {filter}
        </dd>
      </dl>
    </button>
  ));

  return (
    <div id={id} className={" gallery " + className}>
      <div className="row mx-2 mx-md-0 mb-3">
        <div className="col-4 d-none d-md-block"></div>

        <div
          className="btn-filters d-flex align-items-center justify-content-center"
          style={{ marginBottom: "10px" }}
        >
          {btnFilters}
        </div>
      </div>{" "}
      <div className="mx-2 mx-sm-0">
        {images.length > 0 ? (
          <Lightroom images={images.slice()} settings={settings} />
        ) : (
          <div style={{ height: "1200px", width: "100%" }}></div>
        )}
      </div>
    </div>
  );
}

Gallery.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};

export default connect()(multilanguage(Gallery));
