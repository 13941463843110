import React, { Component } from "react";
import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import ArrowBackIos from "@material-ui/icons/ArrowBackIos";

export default class Example extends Component {
  state = {
    goToSlide: 0,
    offsetRadius: 2,
    showNavigation: true,
    config: config.gentle,
  };

  slides = [
    {
      key: 1,
      content: (
        <img
          src="/assets/images/sohoweb17-min.jpg"
          alt="soho fertilizer damlama gübreleri demir organik"
        />
      ),
    },
    {
      key: 2,
      content: (
        <img
          src="/assets/images/DSCF0663-min.jpg"
          alt="soho fertilizer damlama gübreleri demir organik"
        />
      ),
    },
    {
      key: 3,
      content: (
        <img
          src="/assets/images/soho_1.jpg"
          alt="soho fertilizer damlama gübreleri demir organik"
        />
      ),
    },
    {
      key: 4,
      content: (
        <img
          src="/assets/images/sohoweb16-min.jpg"
          alt="soho fertilizer damlama gübreleri demir organik"
        />
      ),
    },
    {
      key: 5,
      content: (
        <img
          src="/assets/images/sohoweb12-min.jpg"
          alt="soho fertilizer damlama gübreleri demir organik"
        />
      ),
    },
    {
      key: 6,
      content: (
        <img
          src="/assets/images/sohoweb20-min.jpg"
          alt="soho fertilizer damlama gübreleri demir organik"
        />
      ),
    },
    {
      key: 7,
      content: (
        <img
          src="/assets/images/sohoweb17-min.jpg"
          alt="soho fertilizer damlama gübreleri demir organik"
        />
      ),
    },
    {
      key: 8,
      content: (
        <img
          src="/assets/images/sohoweb16-min.jpg"
          alt="soho fertilizer damlama gübreleri demir organik"
        />
      ),
    },
    {
      key: 9,
      content: (
        <img
          src="/assets/images/DSCF0662-min.jpg"
          alt="soho fertilizer damlama gübreleri demir organik"
        />
      ),
    },
    {
      key: 10,
      content: <img src="/assets/images/DSCF0659-min.jpg" alt="8" />,
    },
    {
      key: 11,
      content: <img src="/assets/images/sohoweb3-min.jpg" alt="8" />,
    },
  ].map((slide, index) => {
    return { ...slide, onClick: () => this.setState({ goToSlide: index }) };
  });

  render() {
    return (
      <div className="three_slider">
        <Carousel
          slides={this.slides}
          goToSlide={this.state.goToSlide}
          offsetRadius={this.state.offsetRadius}
          showNavigation={this.state.showNavigation}
          animationConfig={this.state.config}
        />
        <div>
          <ArrowBackIos
            className="forwardandbackicons icon-mobile "
            onClick={() => {
              this.setState({ goToSlide: this.state.goToSlide - 1 });
            }}
          />
          &nbsp; &nbsp; &nbsp; &nbsp;
          <ArrowForwardIosIcon
            className="forwardandbackicons icon-mobile"
            onClick={() => {
              this.setState({ goToSlide: this.state.goToSlide + 1 });
            }}
          />
        </div>
      </div>
    );
  }
}
