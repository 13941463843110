import React from "react";
const FooterComponent = () => {
  return (
    <>
      <section id="contact" className="contact padding-section-footer">
        <div className="row d-flex justify-content-center">
          <div className="col-7 ">
            <div className="contact-head">
              <div className="title-contact text-center">
                <div className="footer">
                  <div id="copyright">
                    Copyright
                    <span>
                      <a
                        target={"_blank"}
                        rel="noreferrer"
                        href="https://osicrew.com/"
                      >
                        {" "}
                        OsiCrew
                      </a>
                    </span>{" "}
                    {new Date().getFullYear()}-All Rights Reserved
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FooterComponent;
