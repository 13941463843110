import Header from "../../Wrapper/Header";
import React, { useState, useEffect } from "react";
import AOS from "aos";
import { Link, animateScroll as scroll } from "react-scroll";
import "../../../node_modules/aos/dist/aos.css";
import Parallax from "react-parallax-scroll";
import Loader from "../Home/Loader";
import PropTypes from "prop-types";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
AOS.init({
  once: true,
});

const BlogComponent = (props) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);

  const Services = () => {
    return (
      <div className="section services" id="services">
        <div className="inner">
          <div>
            <h5
              data-aos="fade-down"
              data-aos-duration="1500"
              data-aos-delay="00"
            >
              <span className="blue">{props.strings["demir_organik"]}</span>{" "}
              {props.strings["hakkimizda_nedensoho_baslik"]}
            </h5>
          </div>
          <div className="cardsContainer">
            <div className="leftSection">
              <div
                className="container"
                data-aos="fade-down-right"
                data-aos-duration="1500"
                data-aos-delay="00"
              >
                <div className="titleWithIMG">
                  <h2> {props.strings["hakkimizda_nedensoho_text1"]}</h2>
                  <h3>{props.strings["hakkimizda_nedensoho_text1_icerik"]}</h3>
                  <Link
                    activeClass="active"
                    to="verify"
                    spy={true}
                    smooth={true}
                    offset={-80}
                    duration={500}
                  ></Link>
                </div>
              </div>
              <div
                className="container"
                data-aos="fade-down-left"
                data-aos-duration="1500"
                data-aos-delay="00"
              >
                <div className="titleWithIMG">
                  <h2>{props.strings["hakkimizda_nedensoho_text2"]}</h2>
                  <h3>{props.strings["hakkimizda_nedensoho_text2_icerik"]}</h3>
                  <Link
                    activeClass="active"
                    to="verify"
                    spy={true}
                    smooth={true}
                    offset={-80}
                    duration={500}
                  ></Link>
                </div>
              </div>
            </div>

            <div
              className="container"
              data-aos="fade-up-right"
              data-aos-duration="1500"
              data-aos-delay="00"
            >
              <div className="titleWithIMG">
                <h2>{props.strings["hakkimizda_nedensoho_text3"]}</h2>
                <h3>{props.strings["hakkimizda_nedensoho_text3_icerik"]}</h3>
                <Link
                  activeClass="active"
                  to="verify"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}
                ></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="App">
      <Header></Header>
      <Parallax>
        <Loader loading={loading} />

        <Services />
      </Parallax>
    </div>
  );
};
BlogComponent.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};

export default connect()(multilanguage(BlogComponent));
