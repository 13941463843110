import React, { useRef } from "react";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ContactComponent = (props) => {
  const form = useRef();
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_r0ed87o",
        "template_07eyz4l",
        form.current,
        "22QMggfsoYJvp2Lah"
      )
      .then(
        (result) => {
          toast.success("Mesajınız gönderildi");
        },
        (error) => {
          toast.error("Mesajınız gönderilmedi!");
        }
      );
  }
  return (
    <>
      {" "}
      <ToastContainer />
      <section id="contact" className="contact padding-section-2">
        <div className="row d-flex justify-content-center">
          <div className="col-12">
            <div className="contact-head">
              <div className="title-contact d-flex justify-content-center">
                <span className="main-title">
                  {props.strings["iletisim_1"]}
                  <span className="blue"> {props.strings["iletisim_2"]}</span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row ml-4">
            <div className="contact-row justify-content-center ">
              <div
                className="col-lg-3 col-md-12 col-sm-12"
                style={{ paddingRight: "30px" }}
              >
                <div className="row">
                  <div className="grid-contact mt-4">
                    <i className="fa  fa-globe" />
                    <div className="contact-content">
                      <p>
                        <strong>{props.strings["londra"]}:</strong>
                        <br />
                        106 Church Road, Richmond Surrey TW10 6LW
                        <br />
                        United Kingdom
                      </p>
                      <p>
                        <a href="tel:447517337220">+44 (7517) 337220</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ paddingBottom: "10px" }}>
                  <div className="grid-contact mt-4">
                    <i className="fa  fa-home" />
                    <div className="contact-content">
                      <p>
                        <strong>{props.strings["ankara"]}</strong>
                        <br />
                    
                        Dağyaka Mah. 2022 Cad. No:14 
                        <br />
                        Kahramankazan / ANKARA
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row " style={{ paddingBottom: "10px" }}>
                  <div className="grid-contact">
                    <i className="fa fa-phone" />
                    <div className="contact-content">
                      <p>
                        <strong>{props.strings["tel"]}</strong>
                        <br />
                        <a href="tel:905322418171">+90 (532) 241 81 71</a>
                      </p>
                      <p>
                        <strong>{props.strings["inter"]}</strong>
                        <br />
                        <a href="tel:905300816634">+90 (530) 081 66 34</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ paddingBottom: "10px" }}>
                  <div className="grid-contact">
                    <i className="fa fa-envelope-o" />
                    <div className="contact-content">
                      <p>
                        <strong>E-mail:</strong>
                        <br />
                        <a href="mailto:info@demirorganik.com">
                          info@demirorganik.com
                        </a>
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 dashContact p-3">
                <form
                  ref={form}
                  onSubmit={sendEmail}
                  className="message-form clear"
                >
                  <div className="row">
                    <p className="col-md-12 col-sd-6 message-form-author">
                      <input
                        id="author"
                        name="name"
                        required
                        type="text"
                        placeholder={props.strings["ad-soyad"]}
                        size={30}
                        aria-required="true"
                      />
                    </p>
                  </div>
                  <div className="row">
                    <p className="col-md-12 col-sd-6 message-form-email">
                      <input
                        id="email"
                        name="email"
                        type="text"
                        required
                        placeholder={props.strings["e-mail"]}
                        size={30}
                        aria-required="true"
                      />
                    </p>
                  </div>
                  <div className="row">
                    <p className="col-md-12 col-sd-6 message-form-phone">
                      <input
                        id="phone"
                        name="phone"
                        required
                        type="tel"
                        placeholder={props.strings["telefon"]}
                        size={20}
                        aria-required="true"
                      />
                    </p>
                  </div>
                  <div className="row">
                    <p className="col-md-12 col-sd-6 message-form-company">
                      <input
                        id="company"
                        name="company"
                        required
                        type="text"
                        placeholder={props.strings["sirket"]}
                        size={30}
                        aria-required="true"
                      />
                    </p>
                  </div>
                  <div className="row">
                    <p className="col-md-12 col-sd-6 message-form-message message_home">
                      <textarea
                        id="message"
                        name="message"
                        cols={20}
                        rows={10}
                        placeholder={props.strings["mesaj"]}
                        aria-required="true"
                      />
                    </p>
                  </div>
                  <div className="d-flex justify-content-center">
                    {" "}
                    <Button
                      className="form-submit rectangle-button green medium"
                      name="submit"
                      type="submit"
                      id="submit"
                      defaultValue="Send"
                    >
                      {props.strings["gonder"]}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="map">
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6113.850211484385!2d32.746113!3d39.987779!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d3498c0307c9ab%3A0xac77d4b45f1ad8d5!2sSerhat%2C%201417.%20Sk.%20No%3A48%2C%2006378%20%C4%B0vedik%20Osb%2FYenimahalle%2FAnkara!5e0!3m2!1str!2str!4v1664961118746!5m2!1str!2str"
            height={450}
            style={{ border: 0, width: "100vw" }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
      </section>
    </>
  );
};

ContactComponent.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};

export default connect()(multilanguage(ContactComponent));
